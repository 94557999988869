






























































































import { VuetifyForm } from "@/shared/types";
import { Notif } from "@/store";
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { required } from "@/shared/validates";
import {
  companyDetailType,
  businessSectorType,
} from "@/api/api.types";
import {
  get_business_sector,
  patch_new_company,
  post_new_company,
} from "@/api";

@Component({ name: "company-add" })
export default class AddCompany extends Vue {
  @Prop({ required: true }) readonly value!:
    | boolean
    | companyDetailType["data"];

  required = required;

  companyAdd = null as unknown as boolean;

  loading = false;

  formData = {
    name: "",
    email: "",
    address: "",
    phone: "",
    registrationId: "",
    businessSector: "",
    about: "",
    fax: "",
  };

  businessSectorList =
    [] as businessSectorType["data"]["_embedded"]["business_sector"];

  uuid = null as null | string;

  /*------- METHODS ---------*/
  async fetchBusinessSector(): Promise<void> {
    try {
      const resp = await get_business_sector({ limit: -1 });
      this.businessSectorList = resp.data._embedded.business_sector;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("submit")
  async submit(): Promise<void> {
    this.loading = true;
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      const form = {
        ...this.formData,
      };
      if (!this.uuid) {
        await post_new_company(form);
        Notif.notif_success("Company Successfully Addedd !!!");
        window.location.reload();
      } else {
        await patch_new_company(this.uuid, form);
        this.close();
        Notif.notif_success("Company Successfully Updated !!!");
        window.location.reload();
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
    console.log(this.formData);
  }

  @Emit("input")
  close(): null {
    this.formData = {
      name: "",
      email: "",
      address: "",
      about: "",
      fax: "",
      businessSector: "",
      registrationId: "",
      phone: "",
    };
    // this.date = [];
    this.uuid = null;
    return null;
  }

  /* Life-cycle Methods */
  @Watch("value")
  onValuechange(now: boolean | companyDetailType["data"]): void {
    if (now == true) {
      this.uuid = null;
    } else if (now) {
      this.formData = {
        name: now.name,
        email: now.email,
        address: now.address,
        fax: now.fax,
        businessSector: now.businessSector.uuid,
        registrationId: now.registrationId,
        phone: now.phone,
        about: now.about,
      };
      this.uuid = now.uuid;
    }
  }

  mounted(): void {
    this.fetchBusinessSector();
  }
}
