import { AppModule } from "@/shared/types";
import CheckoutIndex from "./index.vue"

const CheckoutModule: AppModule = {
    rootLink: "/checkout",
    rootName: "Check Out",
    rootIcon: "mdi-inbox-arrow-up",
    layout: "SidebarLayout",
    authRequired: true,
    childrenLink: [
        {
            path: "/checkout",
            name: "Check Out",
            component: CheckoutIndex
        }
    ]
}

export default CheckoutModule;