

























































































import { VuetifyForm } from "@/shared/types";
import { Notif } from "@/store";
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { required } from "@/shared/validates";
import {
  companyType,
  branchType,
  departmentDetailType,
} from "@/api/api.types";
import { get_branch, get_company, patch_new_department, post_new_department } from "@/api";

@Component({ name: "department-add" })
export default class AddDepartment extends Vue {
  @Prop({ required: true }) readonly value!:
    | boolean
    | departmentDetailType["data"];

  required = required;

  departmentAdd = null as unknown as boolean;

  loading = false;

  formData = {
    name: "",
    email: "",
    address: "",
    phone: "",
    extPhone: "",
    company: "",
    branch: "",
    fax: "",
  };

  companyList = [] as companyType["data"]["_embedded"]["company"];
  branchList = [] as branchType["data"]["_embedded"]["branch"];

  uuid = null as null | string;

  /*------- METHODS ---------*/
  async fetchCompany(): Promise<void> {
    try {
      const resp = await get_company({ limit: -1 });
      this.companyList = resp.data._embedded.company;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async fetchBranch(): Promise<void> {
      try {
          const resp = await get_branch({ limit: -1});
          this.branchList = resp.data._embedded.branch
      } catch (error) {
          Notif.notif_api_error(error)
      }
  }

  @Emit("submit")
  async submit(): Promise<void> {
    this.loading = true;
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      const form = {
        ...this.formData,
      };
      if (!this.uuid) {
        await post_new_department(form);
        Notif.notif_success("Department Successfully Addedd !!!");
        window.location.reload();
      } else {
        await patch_new_department(this.uuid, form);
        this.close();
        Notif.notif_success("Department Successfully Updated !!!");
        window.location.reload();
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
    console.log(this.formData);
  }

  @Emit("input")
  close(): null {
    this.formData = {
      name: "",
      email: "",
      address: "",
      extPhone: "",
      phone: "",
      branch: "",
      company: "",
      fax: "",
    };
    // this.date = [];
    this.uuid = null;
    return null;
  }

  /* Life-cycle Methods */
  @Watch("value")
  onValuechange(now: boolean | departmentDetailType["data"]): void {
    if (now == true) {
      this.uuid = null;
    } else if (now) {
      this.formData = {
        name: now.name,
        email: now.email,
        address: now.address,
        branch: now.branch.uuid,
        company: now.company.uuid,
        extPhone: now.extPhone,
        phone: now.phone,
        fax: now.fax,
      };
      this.uuid = now.uuid;
    }
  }

  mounted(): void {
    this.fetchCompany();
    this.fetchBranch();
  }
}
