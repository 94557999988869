















































import { get_item_category } from "@/api";
import { itemListCategoryType  } from "@/api/api.types";
import { route_replace } from "@/shared/utils";
import { Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import CategoryAdd from "./CategoryAdd.vue"

@Component({ name: "category", components: {CategoryAdd} })
export default class AddCategory extends Vue {
  /** Variable */

  tableData = [] as itemListCategoryType["data"]["_embedded"]["item_category"];
  loading = false;
  categoryAdd = null as unknown as boolean

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Category", value: "codeCategory" },
      { text: "Note", value: "note" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /** ----------  METHODS ------------ */
  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_item_category({
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.item_category;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { page: toPage });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

  mounted(): void {
    this.fetchData();

    // const title = this.$route.name;
    // doInterval(this.fetchData, title);
  }
}
