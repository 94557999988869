

















































import { Component, Vue } from "vue-property-decorator";
import BusinessAdd from "./BusinessAdd.vue";
import { DataTableHeader } from "vuetify";
import { businessSectorType } from "@/api/api.types";
import { get_business_sector } from "@/api";
import { route_replace } from "@/shared/utils";
import { Notif } from "@/store";

@Component({ name: "business-index", components: { BusinessAdd } })
export default class BusinessIndex extends Vue {
  /** Variavble */

  dialog = null as unknown as boolean;

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  tableData = [] as businessSectorType["data"]["_embedded"]["business_sector"];

  tableFilter = {
    name: this.$route.query.name || null,
    // is_available: this.$route.query.is_available || 1,
    // department_uuid: this.$route.query.department_uuid || null,
  };

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Note", value: "note" },
    ];
  }

  /** methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_business_sector({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.business_sector;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (err) {
      Notif.notif_api_error(err);
    } finally {
      this.loading = false;
    }
  }

  /** Life-cycle Methods */
  mounted(): void {
    this.fetchData();
  }
}
