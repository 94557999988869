const API = {

    // Auth

    OAUTH: 'oauth',
    REVOKE: 'api/v1/authrevoke',
    ME: 'api/me',

    // Customers
    CUSTOMER: '/api/v1/customer',

    /*---------Settings -------- */
    BUSINES_SECTOR: '/api/v1/business-sector',
    COMPANY: '/api/v1/company',
    BRANCH: '/api/v1/branch',
    DEPARTMENT: '/api/v1/department',

    /** ------- Items -------- */
    ITEM: '/api/v1/item',
    ITEM_CATEGORY: '/api/v1/item-category',
    ITEM_UNIT: '/api/v1/item-unit',
}

export default API