import router from "@/router";
import { Auth } from "@/store";
import moment from "moment";
import config from "./config";

/**
 * Adding query to
 * the path
 * @param path string
 * @param query string
 */
export const route_replace = (path: string, query: Record<string, any>): void => {
    Object.keys(query).forEach((key) => {
        if (query[key] == null || query[key] == "") {
            query[key] = undefined;
        }
    });
    router.replace({ path, query: query }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
            throw error;
        }
    });
};

/**
 * Change time format,
 * depends on timezone
 * @param time string
 * @param format string
 */
export const time_format = (time: string, format?: string): string => {
    if (!format) format = "DD MMM YYYY HH:mm";

    return moment(time).format(format);
};

/**
 * Get Array Sum
 * @param array number
 */
export const array_sum = (array: number[]): number => {
    if (array.length == 0) return 0
    return array.reduce((prev, curr) => { return prev + curr })
};

/**
 * For export link
 * @param href string
 * @param filter any
 */
export const open_export = (
    href: string,
    filter?: Record<string, any>
): void => {
    if (filter) {
        Object.keys(filter).forEach((key) => {
            if (filter[key] == null || filter[key] == "") {
                filter[key] = undefined;
            }
        });
    }
    const basUrl = config.BASE_URL.split("https:")[1];
    const url = router.resolve({
        path: basUrl + href,
        query: { token: Auth.access_token, ...filter },
    }).href;
    window.open(url.toString(), "_blank");
};