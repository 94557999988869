import { AppModule } from "@/shared/types";

import CustomerIndex from "./index.vue";
import CustomerDetail from "./detail.vue";

 
const CustomerModule: AppModule = {
    rootLink: '/customer',
    rootName: 'Customer',
    rootIcon: 'mdi-account-supervisor-circle',
    layout: "SidebarLayout",
    authRequired: true,
    childrenLink: [
        {
            path: '/',
            name: 'Customer',
            component: CustomerIndex
        },
        {
            path: ':uuid',
            name: 'Detail Customer',
            component: CustomerDetail
        }
    ]
}

export default CustomerModule;