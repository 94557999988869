



















import { Vue, Component, Prop, Watch } from "vue-property-decorator";
// import item from "./AddItem.vue";
import category from "./ItemCategory.vue";
import item from "./ItemList.vue";
import unit from "./unit.vue";

@Component({
  name: "item-index",
  components: { item, category, unit },
})
export default class ItemIndex extends Vue {
  /** Variable */
  currentTab = "Item";

  tabs = ["Item", "Category", "Unit"];

  public get currentTabComponent() {
    return this.currentTab.toLowerCase();
  }
}
