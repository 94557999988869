

































































import { Vue, Component } from "vue-property-decorator";
import { time_format } from "@/shared/utils";
import { get_company_detail } from "@/api";
import { companyDetailType } from "@/api/api.types";
import { Notif } from "@/store";
import StatusChip from "@/components/StatusChip.vue";

@Component({ name: "company-detail", components: {StatusChip} })
export default class CompanyDetail extends Vue {
  // Variable

  time_format = time_format;

  uuid = this.$route.params.uuid;

  detailData = {} as companyDetailType["data"];

  loading = false;

  // METHODS
  async fetchData(): Promise<void> {
      try {
      this.loading = true;
      const resp = await get_company_detail(this.uuid);
      this.detailData = resp.data;
    } catch (error) {
      Notif.notif_api_error(error);
    }
    this.loading = false;
  }

  /** Life-cyce methods */
  mounted(): void {
    this.fetchData();
  }
}
