import { Auth } from "@/store";
import { NavigationGuard } from "vue-router";
import config from "@/shared/config";

const guard: NavigationGuard<Vue> = (to, from, next) => {
    const { authRequired } = to.matched[0].meta
    document.title = `${to.name} | ${config.APP_TITLE}`
    const isLogin = Auth.is_login

    if (authRequired && !isLogin) {
        next({ name: 'Login' })
    } else {
        next()
    }
}

export default guard;