




















































import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { branchType } from "@/api/api.types";
import StatusChip from "@/components/StatusChip.vue"
import { delete_branch, get_branch } from "@/api";
import { route_replace } from "@/shared/utils";
import { Notif } from "@/store";
import BranchAdd from "./BranchAdd.vue"
import ConfirmDialog from "@/components/ConfirmDialog.vue"

@Component({ name: "branch-index", components: { StatusChip, BranchAdd, ConfirmDialog } })
export default class BranchIndex extends Vue {
  /** Variavble */

  branchAdd = null as unknown as boolean;

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  tableData = [] as branchType["data"]["_embedded"]["branch"];
  deleteBranch = null as unknown as string;


  tableFilter = {
    name: this.$route.query.name || null,
    // is_available: this.$route.query.is_available || 1,
    // department_uuid: this.$route.query.department_uuid || null,  
  };

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Address", value: "address" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Status", value: "isActive"},
      { text: "", value: "actions", sortable: false}
    ];
  }

  /** methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_branch({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.branch;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (err) {
      Notif.notif_api_error(err);
    } finally {
      this.loading = false;
    }
  }

   async onDelete(): Promise<void>{
   try {
     await delete_branch(this.deleteBranch)
     Notif.notif_success("Branch Successfully Deleted !!!")
     this.fetchData()
   } catch(error) {
     Notif.notif_api_error(error)
   }
 }


  /** Life-cycle Methods */
  mounted(): void {
    this.fetchData();
  }
}
