import { AppModule } from "@/shared/types";

import ItemIndex from "./index.vue";

const ItemsModule: AppModule = {
    rootLink: '/item',
    rootName: 'Item',
    rootIcon: 'mdi-star-box-multiple-outline',
    layout: "SidebarLayout",
    authRequired: false,
    childrenLink: [
        {
            path: '/item',
            name: 'Item',
            component: ItemIndex
        }
    ]
}

export default ItemsModule;