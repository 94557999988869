import { meType } from './../../api/api.types';
import { authentication, get_profile, refresh_token_api, revoke } from "@/api";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";

export type authData = {
    access_token: string;
    refresh_token: string;
    expires_in: number;
};

type profileType = meType["data"];

@Module({ name: 'Auth' })
export default class AuthStore extends VuexModule {
    /* States */

    private accessToken = ''
    private refreshToken = ''
    private expiresIn = 0
    public profile = {} as profileType;


    /* Getters */

    public get is_login(): boolean {
        return !!this.expiresIn && Date.now() < this.expiresIn;
    }

    public get access_token(): string {
        return this.accessToken
    }

    public get refresh_token(): string {
        return this.refreshToken
    }

    public get expires_in(): number {
        return this.expiresIn
    }

    public get self_profile(): profileType {
        return this.profile;
      }
    
      public get self_role(): string {
        return this.profile.role;
      }
    

    /* Mutations */

    @Mutation
    setLogin(loginData: authData): void {
      this.accessToken = loginData.access_token;
      this.refreshToken = loginData.refresh_token;
      this.expiresIn = Date.now() + loginData.expires_in * 1000;
    }

    @Mutation
    setProfile(profileData: profileType): void {
       this.profile = profileData;       
    }

    /* Actions */

    @Action({ commit: "setLogin", rawError: true })
    async Login(loginData: {
        username: string;
        password: string;
      }): Promise<authData> {
        const resp = await authentication(loginData.username, loginData.password);
        const { access_token, refresh_token, expires_in } = resp.data;
        return { access_token, refresh_token, expires_in };
      }

      @Action({ commit: "setLogin", rawError: true })
      async Refresh(refreshToken: string): Promise<authData> {
        const resp = await refresh_token_api(refreshToken);
        const { access_token, refresh_token, expires_in } = resp.data;
        return { access_token, refresh_token, expires_in };
      }
    
      @Action({ commit: "setProfile", rawError: true })
      async GetProfile(): Promise<profileType> {
        const resp = await get_profile();
        return resp.data;
      } 
    

    @Action({ commit: "setLogin", rawError: true })
    async Logout(): Promise<authData> {
        await revoke(this.access_token);
        const empty = {
            access_token: "",
            refresh_token: "",
            expires_in: 0,
        };
        this.context.dispatch("setProfile", {});
        return empty;
    }

}