import { AppModule } from "@/shared/types";
import DashboardModule from "./Dashboard/dashboard.module";
import LoginModule from "./Login/login.module";
import CustomerModule from "./Customers/customer.module";
import WarehouseModule from "./Warehouse/warehouse.module";
import CheckinModule from "./Checkin/checkin.module";
import CheckoutModule from "./Checkout/checkout.module";
import SettingsModule from "./Settings/setting.module";
import ItemsModule from "./Items/item.module";

const PageModules: AppModule[] = [DashboardModule, LoginModule, CustomerModule, ItemsModule, WarehouseModule, CheckinModule, CheckoutModule, SettingsModule]

export default PageModules;