import { AppModule } from "@/shared/types";
import CheckinIndex from "./index.vue"

const CheckinModule: AppModule = {
    rootLink: "/checkin",
    rootName: "Check In",
    rootIcon: "mdi-inbox-arrow-down",
    layout: "SidebarLayout",
    authRequired: true,
    childrenLink: [
        {
            path: "/checkin",
            name: "Check In",
            component: CheckinIndex
        }
    ]
}

export default CheckinModule;