import { AppModule } from "@/shared/types";
import WarehouseIndex from "./index.vue";

const WarehouseModule: AppModule = {
    rootLink: "/warehouse",
    rootName: "Warehouse",
    rootIcon: "mdi-warehouse",
    layout: "SidebarLayout",
    authRequired: true,
    childrenLink: [
        {
            path: '/warehouse',
            name: 'Warehouse',
            component: WarehouseIndex
        }
    ]
} 

export default WarehouseModule;