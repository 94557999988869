import config from "@/shared/config";
import axios, { AxiosResponse } from "axios";
import * as types from "./api.types";
import URL from "./api.constant";

/* Create api */

export const API = axios.create({
  baseURL: config.BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/* Token */

let interceptor = null as unknown as number;

/* Token - Eject */

export const tokenEject = (): void => {
  if (!interceptor) return;
  API.interceptors.request.eject(interceptor);
  interceptor = null as unknown as number;
};


/* Token - Inject */

export const tokenInject = (access_token: string): void => {
  if (!access_token) return;
  tokenEject();
  interceptor = API.interceptors.request.use((req) => {
    req.headers.Authorization = `Bearer ${access_token}`;
    return req;
  });
};


/* API - Auth */

/* Auth - Login */
export const authentication = async (username: string, password: string): Promise<types.authType> => {
  const data = {
    username,
    password,
    grant_type: "password",
    client_id: config.APP_ID,
    client_secret: config.APP_SECRET,
  };
  const resp: types.authType = await API.post(URL.OAUTH, data);
  return resp;
};

/* Auth - Refresh */

export const refresh_token_api = async (
  refresh_token: string
): Promise<types.authType> => {
  const data = {
    refresh_token,
    grant_type: "refresh_token",
    client_id: config.APP_ID,
    client_secret: config.APP_SECRET,
  };
  const resp: types.authType = await API.post(URL.OAUTH, data);
  return resp;
};


/* Auth - Revoke */
export const revoke = async (
  access_token: string
): Promise<AxiosResponse<any>> => {
  const data = {
    token_type_hint: "access_token",
    token: access_token,
  };
  const resp = await API.post(URL.REVOKE, data);
  return resp;
};


/* Auth - Profile */

export const get_profile = async (): Promise<types.meType> => {
  const resp: types.meType = await API.get(URL.ME);
  return resp;
};


/* API - Customer */
export const post_new_customer = async (formData: types.customerTypeForm): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.CUSTOMER, formData);
  return resp;
}

export const patch_new_customer = async (
  uuid: string,
  formData: types.customerTypeForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.CUSTOMER + '/' + uuid, formData);
  return resp;
}

export const get_customer = async (params?: Record<string, any>): Promise<types.customerType> => {
  const resp = await API.get(URL.CUSTOMER, { params: params })
  return resp;
}

export const get_customer_detail = async (uuid: string): Promise<types.customerDetailType> => {
  const resp = await API.get(URL.CUSTOMER + '/' + uuid)
  return resp;
}

export const delete_customer = async (uuid: string): Promise<void> => {
  await API.delete(URL.CUSTOMER + '/' + uuid)
}

/* API - Bussiness Sector */
export const post_new_business_sector = async (formData: types.businessSectorTypeForm): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.BUSINES_SECTOR, formData);
  return resp;
}

export const get_business_sector = async (params?: Record<string, any>): Promise<types.businessSectorType> => {
  const resp: types.businessSectorType = await API.get(URL.BUSINES_SECTOR, {
    params: params
  })
  return resp;
}

/* API - Company */
export const post_new_company = async (formData: types.companyForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.COMPANY, formData);
  return resp
}

export const patch_new_company = async (
  uuid: string,
  formData: types.companyForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.COMPANY + '/' + uuid, formData);
  return resp;
}

export const get_company = async (params?: Record<string, any>): Promise<types.companyType> => {
  const resp = await API.get(URL.COMPANY, { params: params })
  return resp;
}

export const get_company_detail = async (uuid: string): Promise<types.companyDetailType> => {
  const resp = await API.get(URL.COMPANY + '/' + uuid)
  return resp;
}

export const delete_company = async (uuid: string): Promise<void> => {
  await API.delete(URL.COMPANY + '/' + uuid)
}

/* API - Branch */
export const post_new_branch = async (formData: types.branchTypeForm): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.BRANCH, formData);
  return resp
}

export const patch_new_branch = async (
  uuid: string,
  formData: types.branchTypeForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.BRANCH + '/' + uuid, formData);
  return resp;
}

export const get_branch = async (params?: Record<string, any>): Promise<types.branchType> => {
  const resp = await API.get(URL.BRANCH, { params: params })
  return resp;
}

export const get_branch_detail = async (uuid: string): Promise<types.branchDetailType> => {
  const resp = await API.get(URL.BRANCH + '/' + uuid)
  return resp;
}

export const delete_branch = async (uuid: string): Promise<void> => {
  await API.delete(URL.BRANCH + '/' + uuid)
}


/* API - Department */
export const post_new_department = async (formData: types.departmentTypeForm): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.DEPARTMENT, formData);
  return resp
}

export const patch_new_department = async (
  uuid: string,
  formData: types.departmentTypeForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.DEPARTMENT + '/' + uuid, formData);
  return resp;
}

export const get_department = async (params?: Record<string, any>): Promise<types.departmentType> => {
  const resp = await API.get(URL.DEPARTMENT, { params: params })
  return resp;
}

export const get_deparment_detail = async (uuid: string): Promise<types.departmentDetailType> => {
  const resp = await API.get(URL.DEPARTMENT + '/' + uuid)
  return resp;
}

/* Item - API */
export const get_item_list = async (params?: Record<string, any>): Promise<types.itemListType> => {
  const resp = await API.get(URL.ITEM, { params: params })
  return resp;
}

export const get_item_list_detail = async (uuid: string): Promise<types.itemListDetailType> => {
  const resp = await API.get(URL.ITEM + '/' + uuid)
  return resp;
}

export const post_new_item = async (formData: types.itemTypeForm): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.ITEM, formData);
  return resp
}

export const patch_new_item = async (
  uuid: string,
  formData: types.itemTypeForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.ITEM + '/' + uuid, formData);
  return resp;
}

export const delete_item = async (uuid: string): Promise<void> => {
  await API.delete(URL.ITEM + '/' + uuid)
}

/* Item Category - API */
export const get_item_category = async (params?: Record<string, any>): Promise<types.itemListCategoryType> => {
  const resp = await API.get(URL.ITEM_CATEGORY, { params: params })
  return resp;
}

export const get_item_category_detail = async (uuid: string): Promise<types.itemListCategoryDetailType> => {
  const resp = await API.get(URL.ITEM_CATEGORY + '/' + uuid)
  return resp;
}

export const post_new_item_category = async (formData: types.itemCategoryForm): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.ITEM_CATEGORY, formData);
  return resp
}

export const patch_new_item_category = async (
  uuid: string,
  formData: types.itemCategoryForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.ITEM_CATEGORY + '/' + uuid, formData);
  return resp;
}

export const delete_item_category = async (uuid: string): Promise<void> => {
  await API.delete(URL.ITEM_CATEGORY + '/' + uuid)
}

/** Item Unit */

export const get_item_unit = async (params?: Record<string, any>): Promise<types.itemUnitType> => {
  const resp = await API.get(URL.ITEM_UNIT, { params: params })
  return resp
}

export const get_item_unit_detail = async (uuid: string): Promise<types.itemUnitDetailType> => {
  const resp = await API.get(URL.ITEM_UNIT + '/' + uuid)
  return resp
}

export const post_item_unit = async (formData: types.itemUnitForm): Promise<AxiosResponse<any>> => {
  const resp = await API.post(URL.ITEM_UNIT, formData)
  return resp
}

export const patch_item_unit = async (
  uuid: string,
  formData: types.itemUnitForm
): Promise<AxiosResponse<any>> => {
  const resp = await API.patch(URL.ITEM_UNIT + '/' + uuid, formData)
  return resp
}

export const delete_item_unit = async (uuid: string): Promise<void> => {
  await API.delete(URL.ITEM_UNIT + '/' + uuid)
}


/* Counter - Total */
