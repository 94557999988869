const config = {

    // Branding
    APP_NAME: process.env.VUE_APP_NAME || 'Warehouse Management Systen',
    APP_TITLE: process.env.VUE_APP_TITLE || 'SMS',
    APP_COPYRIGHT: process.env.VUE_APP_COPYRIGHT,

    // API
    APP_SECRET: process.env.VUE_APP_SECRET || 'd979e29f23f',
    APP_ID: process.env.VUE_APP_ID || 'iqs-web-admin',
    BASE_URL: process.env.VUE_APP_API_URL || 'https://sms.xtend.my.id',

    // OTHERS
    REFRESH_INTERVAL: process.env.VUE_APP_REFRESH_INTERVAL || 60000
}

export default config