





















































import { Component, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { departmentType } from "@/api/api.types";
import { get_department } from "@/api";
import { route_replace } from "@/shared/utils";
import { Notif } from "@/store";
import departmentAdd from "./DepartmentAdd.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

@Component({
  name: "business-index",
  components: { departmentAdd, ConfirmDialog },
})
export default class BusinessIndex extends Vue {
  /** Variavble */

  departmentAdd = null as unknown as boolean;
  deleteDepartment = null as unknown as string;

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  tableData = [] as departmentType["data"]["_embedded"]["department"];

  tableFilter = {
    name: this.$route.query.name || null,
    // is_available: this.$route.query.is_available || 1,
    // department_uuid: this.$route.query.department_uuid || null,
  };

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Company", value: "company.name" },
      { text: "Branch", value: "branch.name" },
      { text: "", value: "actions" },
    ];
  }

  /** methods */

  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_department({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.department;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (err) {
      Notif.notif_api_error(err);
    } finally {
      this.loading = false;
    }
  }

  /** Life-cycle Methods */
  mounted(): void {
    this.fetchData();
  }
}
