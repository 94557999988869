


















































import router from "@/router";
import { Auth, Notif } from "@/store";
import { Component, Vue } from "vue-property-decorator";
// import config from "@/shared/config"

@Component({ name: 'login-index' })
export default class LoginIndex extends Vue {
  /* Variables */

  image = require("../../assets/xtendlogo.png")
  showPassword = false
  loginData = {
    username: "",
    password: ""
  }

  loading = false

  /* Computed */



  /* Methods */

  handleClick():void{
    this.showPassword = !this.showPassword
  }

  async login(): Promise<void> {
    this.loading = true
    try {
      const resp = await Auth.Login({...this.loginData})
      if (resp) {
        Auth.GetProfile().then((user) => {
          router.replace({ name: 'Dashboard' })
          Notif.notif_info(`Welcome back ${user.firstName}!`)
        });
      }
    } catch (error) {
      Notif.notif_api_error(error)
    } finally {
      this.loading = false
    }
  }

  /* Life-cycle Methods */

  created(): void {
    if (Auth.is_login) {
      router.replace({ name: "Dashboard" });
    }

    // console.log(config);
    
  }

}
