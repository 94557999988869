










































import { Component, Vue, Watch } from "vue-property-decorator";
import ConfirmDialog from "@/components/ConfirmDialog.vue"
import CustomerAdd from "./CustomerAdd.vue";
import { Notif } from "@/store";
import { route_replace } from "@/shared/utils";
import { DataTableHeader } from "vuetify";
import { customerType, customerDetailType } from "@/api/api.types";
import { delete_customer, get_customer } from "@/api";

@Component({ name: "customer-index", components: { CustomerAdd, ConfirmDialog} })
export default class CustomerIndex extends Vue {
  /** Variavble */

  tableData = [] as customerType["data"]["_embedded"]["customer"];

  tableFilter = {
    search_name: this.$route.query.search_name || null,
  };

  loading = false;

  page = {
    page_count: 0,
    page_size: 0,
    page: 1,
  };

  addCustomer = null as unknown as boolean | customerDetailType["data"];
  deleteCustomer = null as unknown as string;

  public get TableHeader(): DataTableHeader[] {
    return [
      { text: "Name", value: "name" },
      { text: "Address", value: "address" },
      { text: "City", value: "city" },
      { text: "Postal Code", value: "postalCode" },
      { text: "State", value: "state" },
      { text: "Description", value: "description" },
      { text: "", value: "actions", sortable: false },
    ];
  }

  /** ----------  METHODS ------------ */
  async fetchData(toPage = this.page.page): Promise<void> {
    if (toPage == 0) toPage = 1;
    this.loading = true;
    try {
      const resp = await get_customer({
        ...this.tableFilter,
        page: toPage,
      });
      const { page, page_count, page_size } = resp.data;
      this.tableData = resp.data._embedded.customer;
      this.page = { page, page_count, page_size };
      route_replace(this.$route.path, { ...this.tableFilter, page: toPage });
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
  }

 async onDelete(): Promise<void>{
   try {
     await delete_customer(this.deleteCustomer)
     Notif.notif_success("Customer Successfully Deleted !!!")
     this.fetchData()
   } catch(error) {
     Notif.notif_api_error(error)
   }
 }

  /* Life-cycle Methods */

  mounted(): void {
    this.fetchData();

    // const title = this.$route.name;
    // doInterval(this.fetchData, title);
  }

  @Watch("page.page")
  onPageChange(now: number): void {
    if (now) this.fetchData();
  }
}
