import { AppModule } from "@/shared/types";

import SettingBusiness from "./BusinessSector/index.vue"
import Company from "./Company/index.vue"
import CompanyDetail from "./Company/detail.vue"
import Department from "./Department/index.vue"
// import Tax from "./Tax/index.vue"
import Branch from "./Branch/index.vue"
import BranchDetail from "./Branch/detail.vue"
import DepartmentDetail from "./Department/detail.vue"

const SettingsModule: AppModule = {
    rootLink: '/settings',
    rootName: 'Settings',
    rootIcon: 'mdi-cog-sync-outline',
    layout: 'SidebarLayout',
    authRequired: true,
    childrenLink: [
        {
            path: 'business-sector',
            name: 'Business Sector',
            component: SettingBusiness
        },
        {
            path: 'branch',
            name: 'Branch',
            component: Branch
        },
        {
            path: 'branch/:uuid',
            name: 'Branch Detail',
            component: BranchDetail
        },
        {
            path: 'company',
            name: 'Company',
            component: Company,
        },
        {
            path: 'company/:uuid',
            name: 'Company Detail',
            component: CompanyDetail
        },
        {
            path: 'department',
            name: 'Department',
            component: Department
        },
        {
            path: 'department/:uuid',
            name: 'Department Detail',
            component: DepartmentDetail
        },
        // {
        //     path: 'tax',
        //     name: 'Tax',
        //     component: Tax
        // }
    ]
}

export default SettingsModule