







// import { Auth } from '@/store'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class DashboardIndex extends Vue{
}
