/**
 * checks if the field has been filled,
 * otherwise return error message
 * @param value
 */
export const required = (value: string): true | string => !!value || "Required";

/**
 * this field can't be less than 8 char,
 * otherwise return error message
 * @param value
 */
export const eightChar = (value: string): boolean | string =>
    (value && value.length >= 8) || "Min 8 Character";