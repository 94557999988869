























































































































import { VuetifyForm } from "@/shared/types";
import { Notif } from "@/store";
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { required } from "@/shared/validates";
import {
  customerDetailType,
  businessSectorType,
} from "@/api/api.types";
import {
  get_business_sector,
  patch_new_customer,
  post_new_customer,
} from "@/api";

@Component({ name: "customer-add" })
export default class AddCustomer extends Vue {
  @Prop({ required: true }) readonly value!:
    | boolean
    | customerDetailType["data"];

  required = required;

  addCustomer = null as unknown as boolean;

  loading = false;

  formData = {
    name: "",
    email: "",
    address: "",
    description: "",
    website: "",
    city: "",
    state: "",
    postalCode: "",
    whatsapp: "",
    salesType: "",
    fax: "",
    businessSector: "",
  };

  businessSectorList =
    [] as businessSectorType["data"]["_embedded"]["business_sector"];

  uuid = null as null | string;

  /*------- METHODS ---------*/
  async fetchBusinessSector(): Promise<void> {
    try {
      const resp = await get_business_sector({ limit: -1 });
      this.businessSectorList = resp.data._embedded.business_sector;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("submit")
  async submit(): Promise<void> {
    this.loading = true;
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      const form = {
        ...this.formData,
      };
      if (!this.uuid) {
        await post_new_customer(form);
        Notif.notif_success("Customer Successfully Addedd !!!");
        window.location.reload();
      } else {
        await patch_new_customer(this.uuid, form);
        this.close();
        Notif.notif_success("Customer Successfully Updated !!!");
        window.location.reload();
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
    console.log(this.formData);
  }

  //   @Emit("input")
  //   close(): null {
  //     (this.$refs.formData as VuetifyForm).reset();
  //     // this.uuid = null;
  //     return null;
  //   }

  @Emit("input")
  close(): null {
    this.formData = {
      name: "",
      email: "",
      address: "",
      description: "",
      website: "",
      city: "",
      state: "",
      postalCode: "",
      whatsapp: "",
      salesType: "",
      fax: "",
      businessSector: "",
    };
    // this.date = [];
    this.uuid = null;
    return null;
  }

  /* Life-cycle Methods */
  @Watch("value")
  onValuechange(now: boolean | customerDetailType["data"]): void {
    if (now == true) {
      this.uuid = null;
    } else if (now) {
      this.formData = {
        name: now.name,
        email: now.email,
        address: now.address,
        description: now.description,
        website: now.website,
        city: now.city,
        state: now.state,
        postalCode: now.postalCode,
        whatsapp: now.whatsapp,
        salesType: now.salesType,
        fax: now.fax,
        businessSector: now.businessSector,
      };
      this.uuid = now.uuid;
    }
  }

  mounted(): void {
    this.fetchBusinessSector();
  }
}
