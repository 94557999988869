









































































































import { VuetifyForm } from "@/shared/types";
import { Notif } from "@/store";
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { required } from "@/shared/validates";
import { companyType, branchDetailType } from "@/api/api.types";
import { get_company, patch_new_branch, post_new_branch } from "@/api";

@Component({ name: "branch-add" })
export default class AddBusiness extends Vue {
  @Prop({ required: true }) readonly value!: boolean | branchDetailType["data"];

  required = required;

  addBranch = null as unknown as boolean;

  loading = false;

  formData = {
    name: "",
    company: "",
    exchangeId: "",
    address: "",
    phone: "",
    extPhone: "",
    fax: "",
    note: "",
    latitude: null,
    longitude: null,
    email: "",
  };

  companyList = [] as companyType["data"]["_embedded"]["company"];

  uuid = null as null | string;

  /*------- METHODS ---------*/

  async fetchCompany(): Promise<void> {
    try {
      const resp = await get_company({ limit: -1 });
      this.companyList = resp.data._embedded.company;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("submit")
  async submit(): Promise<void> {
    this.loading = true;
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      const form = {
        ...this.formData,
      };
      if (!this.uuid) {
        await post_new_branch(form);
        Notif.notif_success("Branch Successfully Addedd !!!");
        window.location.reload();
      } else {
        await patch_new_branch(this.uuid, form);
        this.close();
        Notif.notif_success("Branch Successfully Updated !!!");
        window.location.reload();
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
    console.log(this.formData);
  }

  @Emit("input")
  close(): null {
    this.formData = {
      name: "",
      company: "",
      exchangeId: "",
      address: "",
      phone: "",
      extPhone: "",
      fax: "",
      note: "",
      latitude: null,
      longitude: null,
      email: "",
    };
    // this.date = [];
    this.uuid = null;
    return null;
  }

  /* Life-cycle Methods */
  @Watch("value")
  onValuechange(now: boolean | branchDetailType["data"]): void {
    if (now == true) {
      this.uuid = null;
    } else if (now) {
      this.formData = {
        name: now.name,
        company: now.company,
        exchangeId: now.exchangeId,
        address: now.address,
        phone: now.phone,
        extPhone: now.extPhone,
        fax: now.fax,
        note: now.note,
        longitude: now.longitude,
        latitude: now.latitude,
        email: now.email,
      };
      this.uuid = now.uuid;
    }
  }

  mounted(): void {
    this.fetchCompany();
  }
}
