
















































































import { time_format } from "@/shared/utils"
import { get_customer_detail } from "@/api";
import { customerDetailType } from "@/api/api.types";
import { Notif } from "@/store";
import { Vue, Component } from "vue-property-decorator";

@Component({ name: "customer-detail" })
export default class CustomerDetail extends Vue {
    /** VARIABLE */
    
    time_format = time_format

    uuid = this.$route.params.uuid;

    detailData = {} as customerDetailType["data"];

    loading = false



    /** METHODS */
    async fetchData(): Promise<void>{
        this.loading = true
        try {
            const resp = await get_customer_detail(this.uuid)
            this.detailData = resp.data;
        } catch (error) {
            Notif.notif_api_error(error)
        }
        this.loading = false
    }

/* Life-cycle Methods */

  mounted(): void {
    this.fetchData();
  }
}
