
























































































import { VuetifyForm } from "@/shared/types";
import { Notif } from "@/store";
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { required } from "@/shared/validates";
import {
  itemListCategoryType,
  itemUnitType,
  itemListDetailType,
} from "@/api/api.types";
import {
  get_item_category,
  get_item_unit,
  patch_new_item,
  post_new_item,
} from "@/api";

@Component({ name: "item-add" })
export default class AddItem extends Vue {
  @Prop({ required: true }) readonly value!:
    | boolean
    | itemListDetailType["data"];

  required = required;

  departmentAdd = null as unknown as boolean;

  loading = false;

  formData = {
    name: "",
    itemDescription: "",
    itemId: "",
    image: "",
    account: "",
    itemCategory: "",
    itemUnit: "",
  };

  categoryList =
    [] as itemListCategoryType["data"]["_embedded"]["item_category"];
  unitList = [] as itemUnitType["data"]["_embedded"]["item_unit"];

  uuid = null as null | string;

  /*------- METHODS ---------*/
  async fetchCategory(): Promise<void> {
    try {
      const resp = await get_item_category({ limit: -1 });
      this.categoryList = resp.data._embedded.item_category;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  async fetchUnit(): Promise<void> {
    try {
      const resp = await get_item_unit({ limit: -1 });
      this.unitList = resp.data._embedded.item_unit;
    } catch (error) {
      Notif.notif_api_error(error);
    }
  }

  @Emit("submit")
  async submit(): Promise<void> {
    this.loading = true;
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      const form = {
        ...this.formData,
      };
      if (!this.uuid) {
        await post_new_item(form);
        Notif.notif_success("Item Successfully Addedd !!!");
        window.location.reload();
      } else {
        await patch_new_item(this.uuid, form);
        this.close();
        Notif.notif_success("Item Successfully Updated !!!");
        window.location.reload();
      }
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
    console.log(this.formData);
  }

  @Emit("input")
  close(): null {
    this.formData = {
      name: "",
      itemDescription: "",
      itemId: "",
      image: "",
      account: "",
      itemCategory: "",
      itemUnit: "",
    };
    // this.date = [];
    this.uuid = null;
    return null;
  }

  /* Life-cycle Methods */
  @Watch("value")
  onValuechange(now: boolean | itemListDetailType["data"]): void {
    if (now == true) {
      this.uuid = null;
    } else if (now) {
      this.formData = {
        name: now.name,
        itemDescription: now.itemDescription,
        itemId: now.itemId,
        image: now.image,
        account: now.account,
        itemCategory: now.itemCategory,
        itemUnit: now.itemUnit,
      };
      this.uuid = now.uuid;
    }
  }

  mounted(): void {
    this.fetchCategory();
    this.fetchUnit();
  }
}
