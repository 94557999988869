




































import { VuetifyForm } from "@/shared/types";
import { Notif } from "@/store";
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import { required } from "@/shared/validates";
import { customerDetailType } from "@/api/api.types";
import {
  post_new_business_sector,
} from "@/api";

@Component({ name: "business-add" })
export default class AddBusiness extends Vue {
  @Prop({ required: true }) readonly value!:
    | boolean
    | customerDetailType["data"];

  required = required;

  //   addCustomer = null as unknown as boolean;

  loading = false;

  formData = {
    name: "",
    note: "",
  };

  uuid = null as null | string;

  /*------- METHODS ---------*/
  @Emit("submit")
  async submit(): Promise<void> {
    this.loading = true;
    try {
      if (!(this.$refs.formData as VuetifyForm).validate()) return;
      const form = {
        ...this.formData,
      };
      await post_new_business_sector(form);
      Notif.notif_success("Business Sector Successfully Addedd !!!");
      this.close();
    } catch (error) {
      Notif.notif_api_error(error);
    } finally {
      this.loading = false;
    }
    console.log(this.formData);
  }

  @Emit("input")
  close(): null {
    this.formData = {
      name: "",
      note: "",
    };
    // this.date = [];
    this.uuid = null;
    return null;
  }
}
